<template>
  <div style="text-align: center; padding-top: 25px">
    <h3 v-show="error" style="color: red">No matches found!</h3>
    <div class="loading" v-if="loading">Loading ...</div>
    <div
      v-for="(image, index) in images"
      :key="index"
      class="photo"
      :style="{ border: orange_border ? '1px solid orange' : 'none' }"
    >
      <a
        :href="image.profile_url"
        style="text-decoration: none; color: #fff"
        target="_blank"
      >
        <div class="title">
          {{ image.title }}
        </div>
        <img
          :src="`https://storage.cloud.google.com/similarity-finder/${image.storage_key}`"
          style="object-fit: cover; height: 200px; width: 200px"
          :title="image.title"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "results-overview",
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: Boolean,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    no_profiles: {
      type: Number,
      required: true,
    },
    no_images: {
      type: Number,
      required: true,
    },
    orange_border: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
});
</script>

<style scoped>
.photo {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 65px rgb(0 0 0 / 52%);
  transition: all 0.3s ease;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: center;
  text-transform: capitalize;
  width: 200px;
  font-size: 14px;
}
</style>
