<template>
  <div class="home-wrapper">
    <h2>Face<span style="color: orange">Spy</span></h2>
    <image-uploader-pbp
      @find-similar-clicked="fetch"
      @reset="reset"
      :disabledSearch="disabledSearch"
    />
    <results-overview
      :loading="loading"
      :images="images"
      title=""
      no_images=""
      no_profiles=""
      :error="error"
      :orange_border="true"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ResultsOverview from "@/components/Overview.vue";
import ImageUploaderPbp from "@/components/ImageUploaderPBP.vue";
import FindSimilarService from "@/services/FindSimilarService";

export default defineComponent({
  inject: ["mixpanel"],
  name: "home-pbp",
  components: { ImageUploaderPbp, ResultsOverview },
  data() {
    return {
      loading: false,
      images: [],
      tab: "psos",
      error: false,
      disabledSearch: false,
    };
  },
  methods: {
    fetch(files) {
      if (files.length === 0 || this.disabledSearch) {
        return;
      }
      this.mixpanel.track("fetch_clicked", {
        count: files.length,
      });
      this.loading = true;
      this.disabledSearch = true;
      FindSimilarService.findSimilar(files, this.tab)
        .then((res) => {
          this.images = res.data;
          this.mixpanel.track("similar_images_found", {
            count: files.length,
          });
        })
        .catch((err) => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reset() {
      this.images = [];
      this.error = false;
      this.disabledSearch = false;
      this.mixpanel.track("reset_clicked");
    },
  },
  created() {
    this.mixpanel.track("home_opened");
  },
});
</script>

<style scoped>
.home-wrapper {
  text-align: center;
}
</style>
