import axios from "axios";
import GoogleAuthService from "./GoogleAuthService";
import AuthStore from "./AuthStore";
import { API_URL } from '../config';

const baseURL = API_URL;

// Create axios instance
const api = axios.create({
  baseURL: baseURL,
  timeout: 30000,
});

// Request interceptor to add auth token
api.interceptors.request.use(
  async (config) => {
    // Don't add auth header for auth endpoints
    if (config.url.includes("/auth/") && !config.url.includes("/auth/me")) {
      return config;
    }

    // Try to get auth header from AuthStore
    const authHeader = AuthStore.getAuthHeader();
    if (authHeader) {
      config.headers.Authorization = authHeader;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for token refresh
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is 401 and not from a retry or auth endpoint
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !originalRequest.url.includes("/auth/refresh")
    ) {
      originalRequest._retry = true;

      // Try to refresh the token
      const refreshed = await GoogleAuthService.refreshToken();
      if (refreshed) {
        // Retry original request with new token
        const authHeader = AuthStore.getAuthHeader();
        if (authHeader) {
          originalRequest.headers.Authorization = authHeader;
        }
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
