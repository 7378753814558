import api from "./api";

class FindSimilarService {
  findSimilar(files, endpoint) {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    
    return api
      .post(`/find-similar/${endpoint}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new FindSimilarService();
