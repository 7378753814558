<template>
  <div @paste="handlePaste">
    <div class="upload-box">
      <div
        class="input-wrapper"
        :class="{
          hovered: hovered,
          bborder: files.length === 0,
        }"
        @click="selectFile"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @dragover.prevent
        @dragenter="hovered = true"
        @dragleave="hovered = false"
        @dragstart.prevent
        @drop.prevent="handleFileChange($event.dataTransfer)"
        @change="uploadImageSubmit"
        v-show="files.length === 0"
      >
        <!--        <label for="files" class="image-label">Select photo(s)</label>-->
        <div>Select photo(s) or paste from clipboard (Ctrl+V)</div>
        <input
          type="file"
          id="files"
          multiple
          ref="imageFiles"
          max="5"
          class="chose-files"
          style="visibility: hidden"
        />
      </div>
      <div v-for="(image, index) in baseImages" :key="index" class="photo">
        <img
          :src="image"
          alt="image"
          class="preview-image"
        />
      </div>
    </div>
    <div class="upload-footer" v-show="$refs.imageFiles?.files?.length > 0">
      <a
        class="button-upload"
        style="background: #006cff"
        @click="$emit('findSimilarClicked', files)"
        :class="{ disabled: disabledSearch }"
        >Find similar</a
      >
      <a class="button-upload" style="background: #ff5555a8" @click="reset"
        >Reset</a
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  inject: ["mixpanel"],
  name: "image-uploader-pbp",
  components: {},
  props: {
    disabledSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      files: [],
      baseImages: [],
    };
  },
  methods: {
    selectFile() {
      this.$refs.imageFiles.click();
    },
    uploadImageSubmit() {
      this.mixpanel.track("image_added", {
        count: this.$refs.imageFiles.files.length,
      });
      // take at max 5 images
      if (this.$refs.imageFiles.files.length > 5) {
        const dt = new DataTransfer();
        for (let i = 0; i < 5; i++) {
          dt.items.add(this.$refs.imageFiles.files[i]);
        }
        this.$refs.imageFiles.files = dt.files;
      }
      this.files = this.$refs.imageFiles.files;
      this.baseImages = this.computeBaseImages();
    },
    computeBaseImages() {
      let baseImages = [];
      for (let i = 0; i < this.files.length; i++) {
        const url = URL.createObjectURL(this.files[i]);
        baseImages.push(url);
      }
      return baseImages;
    },
    handleFileChange(dataTransfer) {
      // this.files = dataTransfer.files;
      this.$refs.imageFiles.files = dataTransfer.files;
      this.uploadImageSubmit();
    },
    reset() {
      this.baseImages = [];
      this.$refs.imageFiles.files = new DataTransfer().files;
      this.files = [];
      this.$emit("reset");
    },
    handlePaste(event) {
      // Check if clipboard has items
      if (event.clipboardData && event.clipboardData.items) {
        const items = event.clipboardData.items;
        
        // Loop through clipboard items
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          
          // Check if item is an image
          if (item.type.indexOf('image') !== -1) {
            // Get file from clipboard
            const file = item.getAsFile();
            
            // Create a DataTransfer object to add the file
            const dt = new DataTransfer();
            
            // Add current files if any (up to max 5)
            for (let j = 0; j < Math.min(this.files.length, 4); j++) {
              dt.items.add(this.files[j]);
            }
            
            // Add the new file if we haven't reached 5 files
            if (dt.files.length < 5) {
              dt.items.add(file);
              
              // Update file input and process the image
              this.$refs.imageFiles.files = dt.files;
              this.uploadImageSubmit();
              
              // Track the paste event
              this.mixpanel.track("image_pasted");
            }
            
            break;  // Process only the first image found
          }
        }
      }
    },
  },
});
</script>

<style scoped>
input {
  visibility: hidden;
  position: absolute;
}
.upload-box {
  border: 1px dotted orange;
  border-bottom: unset;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  min-height: 500px;
  display: table;
  position: relative;
  /*padding-top: 25px;*/
}
.input-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding-top: 25px;
}
/* cool animated strips on hover with 0.3 opacity */
.hovered {
  background: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    rgba(60, 60, 60, 0.4) 10px,
    rgba(60, 60, 60, 0.4) 20px
  );
  cursor: pointer;
}
.photo {
  display: inline-block;
  margin: 5px;
  overflow: hidden;
}
.preview-image {
  width: 200px;
  height: 330px;
  object-fit: cover;
  object-position: top; /* Always show the top part of the image */
}
/* stick the footer to the bottom of the parent */
.upload-footer {
  /*position: absolute;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  width: 602px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}
.button-upload {
  display: inline-block;
  width: 50%;
  color: white;
  cursor: pointer;
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.bborder {
  border-bottom: 1px solid orange;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #ccc !important;
}
</style>
